import React, {useEffect, useState} from "react"

import SEO from "../../components/seo"
import {useIntl, Link, navigate} from "gatsby-plugin-intl"
import instance from "../../components/api/httpclient";

const AddCarAlready = (props) => {
  const intl = useIntl();
  const [mailTo, setMailTo] = useState('mailto:');

  useEffect(() => {
    instance.get('/parameters',
    {
      headers: {
        'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
      }
    })
      .then(function (response) {
        let parameter = response.data['hydra:member'][0];
        switch(intl.locale) {
          case 'fr-BE':
            setMailTo('mailto:' + parameter.youplusNissanEmailFrBe);
            break;
          case 'fr-LU':
            setMailTo('mailto:' + parameter.youplusNissanEmailFrLu);
            break;
          case 'nl-BE':
            setMailTo('mailto:' + parameter.youplusNissanEmailNlBe);
            break;
          default:
            setMailTo('mailto:' + parameter.youplusNissanEmailNlNl);
            break;
        }
      });
  }, []);

  return (
    <div>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.add-car-already-associated" })}/>

      <div className="white-container mb-5">
        <div className="text-center mb-4">
          <span className="icon-error fs-40"></span>
        </div>

        <h1 className="text-center">{intl.formatMessage({id: "01_onboarding_add_car_already_associated.title"})}</h1>

        <p className="text-center mb-5">
          {intl.formatMessage({ id: "generic.forms.form_error_message.error_message_vin_already_associated" })} <br />
          {intl.formatMessage({id: "01_onboarding_add_car_already_associated.description"})}
        </p>

        <div className="mb-3">
          <a href={mailTo} className="button red mx-auto">{intl.formatMessage({id: "generic.nav_buttons.contact"})}</a>
        </div>

        <div className="mb-3">

          <Link to={props.currentPage == "mycars" ? "/mycars/add-car" : "/profile/add-car"} className="button white back mx-auto">{intl.formatMessage({id: "generic.nav_buttons.backtoform_button"})}</Link>
        </div>

      </div>
    </div>
  )
};

export default AddCarAlready
